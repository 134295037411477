import React from 'react';
import MiniFooter from '../Footer/MiniFooter';
import MiniHeader, { miniHeaderHeight } from '../MiniHeader';
import styled from '@emotion/styled';
import { Flex } from '../styles';
import { mainBg } from '../styles/Main';
import { Url } from 'url';

type MiniLayoutProps = {
  children?: React.ReactNode,
  miniFooter?: boolean,
  userMenu?: boolean,
  NoBrand?: boolean,
  backArrowLink?: Url | string
} & React.ComponentPropsWithoutRef<typeof Container>;

type MainProps = {
  disableTopGutter?: boolean,
  userMenu?: boolean,
};

const Container = styled(Flex)<MainProps>(({ theme, disableTopGutter, userMenu = false }) => ({
  backgroundColor: theme.palette.background[mainBg][0],
  color: theme.palette.background[mainBg][1],
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(8),
  flexGrow: 1,
  // eslint-disable-next-line max-len
  paddingTop: !disableTopGutter ? `calc(${userMenu ? miniHeaderHeight.withUserMenu : miniHeaderHeight.onlyLogo} + ${theme.spacing(3)})` : undefined,
  [theme.breakpoints.up('md')]: {
    paddingTop: !disableTopGutter ? `calc(${miniHeaderHeight.withUserMenu} + ${theme.spacing(3)})` : undefined,
    padding: theme.spacing(3),
  },
}));

export default function MiniLayout({ children, miniFooter = true, userMenu = false, NoBrand = false,
  backArrowLink, ...rest }: MiniLayoutProps) {
  return (
    <>
      <MiniHeader userMenu={userMenu} NoBrand={NoBrand} backArrowLink={backArrowLink} />
      <Container {...rest} id="main-container" userMenu={userMenu} direction="column" justify="center" align="center">
        {children}
      </Container>
      {miniFooter && <MiniFooter alwaysShow />}
    </>
  );
}
