import React  from 'react';
// import theme from '../theme';
import { Box, Link } from '../styles';
import { BrandFull } from './MenuBrand';
import { LoginLogo } from '../icons';
// import Tooltip from '../Tooltip';
// import { __t } from '../i18n';

const MiniMenuBrand = () => {
  // const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  // const [openTooltip, setOpenTooltip] = useState(false);

  // useEffect(() => {
  //   handleHeaderTooltip();
  //   window.addEventListener('resize', handleHeaderTooltip);
  //   return () => {
  //     window.removeEventListener('resize', handleHeaderTooltip);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const handleHeaderTooltip = () => {
  //   setOpenTooltip(Number(theme.breakpoints.md) > Number(window.innerWidth));
  // };
  return (
    <Box ml={{ md: 3, lg: 4 }}>
      <Link href="/">
        <BrandFull className="desktop-only"/>
        <LoginLogo className="mobile-only"/>
        {/* <Tooltip
          disableMobileHover={openTooltip}
          anchor={referenceElement}
          placement="bottom-end"
          type="text"
        >
          {__t('common.go_to_home_page')}
        </Tooltip> */}
      </Link>
    </Box>
  );
};

export default MiniMenuBrand;
