import React, { useEffect, useState } from 'react';
import { Flex, Icon, Link } from './styles';
import useScrollTrigger from './useScrollTrigger';
import UserMenu from './Header/UserMenu';
import styled from '@emotion/styled';
import { globalPadding } from 'common/Layout/Layout';
import { IconRight } from './icons';
import { Url } from 'url';
import MiniMenuBrand from './Header/MiniMenuBrand';
import { SystemMessage } from 'common/Header/SystemMessage';
import lscache from 'lscache';

export const miniHeaderHeight = {
  onlyLogo: '120px',
  withUserMenu: '66px',
};

const MiniHeaderContainer = styled.header<{ solid?: boolean, system_message: SystemMessage | null }>(({ theme, solid, system_message }) => {
  const bg = theme.palette.background[800][0];
  return ({
    position: 'fixed',
    minHeight: '4rem',
    top: system_message && !system_message?.isClosedBefore ? '48px' : 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    color: theme.palette.foreground[900][0],
    background: `linear-gradient(to bottom, ${bg}, rgba(0,0,0,0))`,
    backgroundColor: solid ? theme.rgba(bg, 1) : theme.rgba(bg, 0),
    transition: 'background-color 0.2s ease',
    '& .mobile-only': {
      [theme.breakpoints.up('md')]: { display: 'none' },
    },
    '& .desktop-only': {
      [theme.breakpoints.down('md')]: { display: 'none' },
    },
    [theme.breakpoints.down('md')]: {
      top: system_message && !system_message?.isClosedBefore ? '72px' : 0,
    },
  });
});

type FlexWrapperType = {
  userMenu?: boolean,
};
const FlexWrapper = styled(Flex)<FlexWrapperType>(({ userMenu = false, theme }) => ({
  justifyContent: !userMenu ? 'center' : 'space-between',
  alignItems: 'center',
  ...theme.mixins.create(globalPadding, (p) => ({ padding: `0 ${theme.spacing(p)}` })),
  '& svg': {
    display: 'block',
    width: 'auto',
  },
  marginTop: userMenu ? theme.spacing(2) : theme.spacing(7),
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    '& svg': {
      height: '2rem',
    },
  },
}));

const IconRightLink = styled(Link)(({ theme }) => ({
  marginLeft: 'auto',
  padding: '1rem',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: '-1rem',
    padding: '0',
  },
}));

export default function MiniHeader({ userMenu = false, NoBrand = false, backArrowLink }: {
  userMenu?: boolean, NoBrand?: boolean,
  backArrowLink?: Url | string
}) {
  const [system_message, setSystemMessage] = useState<SystemMessage | null>(null);
  const trigger = useScrollTrigger(50);

  useEffect(() => {
    setSystemMessage(lscache.get('system_message'));
    window.addEventListener('storage', handleSystemMessage);
    return () => {
      window.removeEventListener('storage', handleSystemMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSystemMessage = () => {
    setSystemMessage(lscache.get('system_message'));
  };

  return (
    <MiniHeaderContainer solid={trigger} system_message={system_message}>
      <FlexWrapper m={2} userMenu={userMenu}>
        {!NoBrand && <MiniMenuBrand/>}
        {backArrowLink &&
          <IconRightLink href={backArrowLink}>
            <Icon size="large" cursor="pointer">
              <IconRight/>
            </Icon>
          </IconRightLink>
        }
        {userMenu && <UserMenu/>}
      </FlexWrapper>
    </MiniHeaderContainer>
  );
}
